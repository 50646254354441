<template>
  <div class="container" @click="handleClick">
    <unicon
      :class="{ refreshLoading: isRefreshing }"
      @click="handleClick"
      name="sync"
      fill="#00f2c3"
      width="18px"
    ></unicon>
  </div>
</template>

<script>
export default {
  name: "base-refresh",
  props: {
    isRefreshing: Boolean,
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style scoped>
.container {
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.refreshLoading {
  animation: refreshAnimation 1.2s linear infinite;
}

@keyframes refreshAnimation {
  0% {
    transform: rotate(0deg);
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }

  100% {
    transform: rotate(360deg);
    opacity: 0.2;
  }
}
</style>