<template>
  <card class="card" title="Lotes">
    <LoadingSpinner :show="loading" />

    <!-- Begin Details Modal   -->
    <div
      class="modal fade"
      id="modalBatchDetail"
      aria-labelledby="modalBatchDetailLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="modalBatchDetailLabel">
              Informações do Lote
            </p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
              <p class="display-4">Número do Lote #{{ batchSeleted.id }}</p>
            </div>

            <table class="table table-responsive-xl text-dark">
              <thead>
                <tr>
                  <th scope="col">
                    <p class="font-weight-bold">IMAGEM</p>
                  </th>
                  <th scope="col"><p class="font-weight-bold">NOME</p></th>
                  <th scope="col">
                    <p class="font-weight-bold">VALIDADE</p>
                  </th>
                  <th scope="col">
                    <p class="font-weight-bold">PREÇO</p>
                  </th>
                  <th scope="col"><p class="font-weight-bold">QUANT</p></th>
                  <th scope="col"><p class="font-weight-bold">TOTAL</p></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, value) in batchSeleted.items" :key="value">
                  <td>
                    <ProductImage class="ml-0" :src="item.image" :size="45" />
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">{{ item.title }}</p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      <ExpirationBadge :date="item.expiration" />
                    </p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      {{ toBRL(item.acquisition_price) }}
                    </p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      {{ item.quantity }}x {{ toBRL(item.acquisition_price) }}
                    </p>
                  </td>
                  <td class="text-dark font-weight-bold">
                    <p class="text-dark font-weight-bold">
                      {{ toBRL(item.acquisition_price * item.quantity) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <a download target="_blank" :href="batchSeleted.nfe">
              <div class="pdf-container">
                <div class="pdf">
                  <p>PDF</p>
                </div>
                <div>
                  <p>Baixar anexo Nota Fiscal</p>
                  <div>
                    <p>{{ dateFormatted(batchSeleted.created_at) }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- End Details Modal   -->

    <div class="card-body">
      <div class="d-flex justify-content-end align-items-center">
        <base-refresh
          class="mr-3"
          :isRefreshing="isRefreshing"
          @click="refreshBatchs"
        ></base-refresh>
        <div style="width: 60px">
          <select
            class="form-control"
            v-model="limitPage"
            @change="handleChangeLimit"
          >
            <option v-for="number in [5, 10, 20]" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
      </div>
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Lote</th>
            <th scope="col">Data do Lote</th>
            <th scope="col">Usuário</th>
            <th scope="col">Email</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lote in lotes" :key="lote.id">
            <td>
              <p class="font-weight-bold">#{{ lote.id }}</p>
            </td>
            <td>
              <p class="font-weight-bold">
                {{ dateFormatted(lote.created_at) }}
              </p>
            </td>

            <td>
              <p class="font-weight-bold">{{ lote.user.name }}</p>
            </td>
            <td>
              <p class="font-weight-bold">{{ lote.user.email }}</p>
            </td>
            <td>
              <base-button
                link
                data-toggle="modal"
                data-target="#modalBatchDetail"
                @click="batchSeleted = lote"
              >
                <unicon name="list-ul" fill="#fff" width="22px"></unicon>
              </base-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <paginate
      :page-count="totalPages"
      :page-range="3"
      :click-handler="handleClickPage"
      prev-text="<"
      next-text=">"
      container-class="paginator-container"
      page-class="page-item"
    >
    </paginate>
  </card>
</template>
<script>
import { InventoryStatus } from "@/services/InventoryStatus";
import moment from "moment";
import LoadingSpinner from "../../components/Spinner.vue";
import Paginate from "vuejs-paginate";
import BaseRefresh from "../../components/BaseRefresh.vue";
import ProductImage from "../../components/ProductImage.vue";
import ExpirationBadge from "../../components/ExpirationBadge.vue";
moment.locale("pt-BR");

export default {
  name: "Batchs",
  components: {
    LoadingSpinner,
    Paginate,
    BaseRefresh,
    ProductImage,
    ExpirationBadge,
  },
  data() {
    return {
      loading: true,
      isRefreshing: false,
      totalPages: 0,
      limitPage: 10,
      page: 1,
      warehouse_id: "",
      company_id: "",
      lotes: [],
      batchSeleted: {},
      INVENTORY_STATUS: InventoryStatus,
    };
  },
  async mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getBatchs();
  },
  methods: {
    handleChangeLimit() {
      this.getBatchs();
    },
    handleClickPage(pageNumber) {
      this.page = pageNumber;
      this.getBatchs();
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    async refreshBatchs() {
      this.isRefreshing = true;
      await this.getBatchs();
    },
    async getBatchs() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          page: this.page,
          limit: this.limitPage,
        };
        const { data } = await this.$http.get("/lote", {
          params,
        });
        this.lotes = data.lotes;
        this.totalPages = data.total_pages;
        this.page = data.page;
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = false;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>

<style lang="css">
.paginator-container {
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  list-style: none;
  color: #d1d2d6;
}

.page-item > a {
  display: block;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  border-radius: 4px;
  color: #f5f5f5;
}

.pdf-container {
  display: flex;
  gap: 20px;
  padding: 24px;
  border: 1px dashed #ccc;
  border-radius: 16px 24px;
  transition: 200ms ease;
}

.pdf-container:hover {
  background: #ffe6e7;
}

.pdf {
  position: relative;
  width: 50px;
  height: 70px;
  background: #e5252a;
  border-radius: 8px;
  border-top-right-radius: 16px;
  overflow: hidden;
}

.pdf::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #ed676a;
  position: absolute;
  border-bottom-left-radius: 4px;
  top: 0;
  right: 0;
}

.pdf p {
  text-align: center;
  line-height: 70px;
  font-weight: 600;
  color: #fff;
}
</style>