<template>
  <div>
    <!-- Modal Update Product -->
    <div
      class="modal fade"
      id="modalUpdateProduct"
      aria-labelledby="modalUpdateProductLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-xl">
        <div class="modal-content py-3">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUpdateProductLabel">
              Atualizar produto
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="createProduct" v-slot="{ handleSubmit }">
              <form
                class="row g-3 justify-content-start"
                @submit.prevent="handleSubmit(onSubmitUpdateProduct)"
              >
                <div class="col-md-12">
                  <ValidationProvider
                    name="Produto"
                    rules="image"
                    ref="provider"
                    v-slot="{ errors }"
                  >
                    <label for="formImage" class="picture">
                      <div class="picture-container">
                        <img :src="productImage" class="picture-image" alt="" />
                      </div>
                      <input
                        @change="handleFileChange"
                        type="file"
                        name="formImage"
                        id="formImage"
                        accept="image/*"
                        tabindex="0"
                      />
                    </label>
                    <p class="fw-normal text-danger p-relative">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Nome"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="inputName" class="form-label text-dark"
                      >Nome do produto
                      <span class="text-danger">(*)</span></label
                    >
                    <input
                      v-model="formProduct.title"
                      type="text"
                      class="form-control text-dark"
                      id="inputName"
                    />
                    <p class="fw-normal text-danger p-relative">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider name="Descrição" v-slot="{ errors }">
                    <label for="inputDescription" class="form-label text-dark"
                      >Descrição do produto</label
                    >
                    <input
                      v-model="formProduct.description"
                      type="text"
                      class="form-control text-dark"
                      id="inputDescription"
                    />
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Produto de estante"
                    rules="required"
                    slot="{ errors }"
                  >
                    <label
                      for="inputProductType"
                      class="form-label mr-2 text-dark"
                      >Produto de estante?</label
                    >
                    <div class="d-flex align-items-center">
                      <input
                        style="width: 20px; height: 20px"
                        class="form-control text-dark"
                        type="checkbox"
                        id="inputProductType"
                        v-model="formProduct.shelf"
                      />
                      <p class="ml-2">Sim/Não</p>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Código de Barras"
                    v-slot="{ errors }"
                  >
                    <label for="inputBarCode" class="form-label text-dark"
                      >Código de Barras</label
                    >
                    <input
                      v-model="formProduct.bar_code"
                      type="text"
                      class="form-control text-dark"
                      id="inputBarCode"
                    />
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Categoria"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="inputCategory" class="form-label text-dark"
                      >Categoria</label
                    >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <multiselect
                        :max-height="200"
                        :maxSelectedLabels="1"
                        id="inputCategory"
                        :showLabels="false"
                        v-model="selectedCategory"
                        :options="categories"
                        placeholder="Categoria"
                        label="name"
                        track-by="name"
                      >
                        <template #option="slotProps">
                          <p class="text-dark">
                            {{ slotProps.option.name }}
                          </p>
                        </template>
                      </multiselect>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#modalUCreateCategory"
                        class="ml-2 bg-dark border-0"
                      >
                        <unicon
                          name="plus"
                          fill="#00f2c3"
                          width="22px"
                        ></unicon>
                      </a>
                    </div>
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="NCM"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <label for="inputNCM" class="form-label text-dark"
                      >Código NCM <span class="text-danger">(*)</span></label
                    >
                    <input
                      v-model="formProduct.cod_ncm"
                      maxlength="8"
                      type="text"
                      class="form-control text-dark"
                      id="inputDescription"
                    />
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Unidade Comercial"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="inputUnidComercial" class="form-label text-dark"
                      >Unid Comercial (UN / KG / L)
                      <span class="text-danger">(*)</span></label
                    >
                    <select
                      v-model="formProduct.commercial_unit"
                      class="form-control text-dark"
                      id="inputUnidComercial"
                    >
                      <option class="bg-dark" selected value="UN">UN</option>
                      <option class="bg-dark" value="L">L</option>
                      <option class="bg-dark" value="KG">KG</option>
                    </select>
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="Unidade Tributável"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label
                      for="inputUnidTributavel"
                      class="form-label text-dark"
                      >Unidade Tributável (UN / KG / L)
                      <span class="text-danger">(*)</span></label
                    >
                    <select
                      v-model="formProduct.tax_unit"
                      class="form-control text-dark"
                      id="inputUnidTributavel"
                    >
                      <option class="bg-dark" selected value="UN">UN</option>
                      <option class="bg-dark" value="L">L</option>
                      <option class="bg-dark" value="KG">KG</option>
                    </select>
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="CFOP"
                    rules="required|numeric"
                    slot="{ errors }"
                  >
                    <label for="inputCFOP" class="form-label text-dark"
                      >CFOP <span class="text-danger">(*)</span></label
                    >
                    <select
                      v-model="formProduct.cfop"
                      class="form-control text-dark"
                      id="inputCFOP"
                    >
                      <option class="bg-dark" value="5101">
                        5.101 – Venda de produção do estabelecimento;
                      </option>
                      <option class="bg-dark" value="5102">
                        5.102 – Venda de mercadoria de terceiros;
                      </option>
                      <option class="bg-dark" value="5103">
                        5.103 – Venda de produção do estabelecimento efetuada
                        fora do estabelecimento;
                      </option>
                      <option class="bg-dark" value="5104">
                        5.104 – Venda de mercadoria adquirida ou recebida de
                        terceiros, efetuada fora do estabelecimento;
                      </option>
                      <option class="bg-dark" value="5115">
                        5.115 – Venda de mercadoria adquirida ou recebida de
                        terceiros, recebida anteriormente em consignação
                        mercantil;
                      </option>
                      <option class="bg-dark" value="5405">
                        5.405 – Venda de mercadoria adquirida ou recebida de
                        terceiros em operação com mercadoria sujeita ao regime
                        de substituição tributária, na condição de contribuinte
                        substituído;
                      </option>
                      <option class="bg-dark" value="5656">
                        5.656 – Venda de combustível ou lubrificante de
                        terceiros, destinados a consumidor final;
                      </option>
                      <option class="bg-dark" value="5667">
                        5.667 – Venda de combustível ou lubrificante a
                        consumidor ou usuário final estabelecido em outra UF;
                      </option>
                      <option class="bg-dark" value="5933">
                        5.933 – Prestação de serviço tributado pelo ISSQN*
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="ICMS Origem"
                    rules="required"
                    slot="{ errors }"
                  >
                    <label for="inputICMSOrigem" class="form-label text-dark"
                      >ICMS Origem <span class="text-danger">(*)</span></label
                    >
                    <select
                      v-model="formProduct.icms_origin"
                      class="form-control text-dark"
                      id="inputICMSOrigem"
                    >
                      <option class="bg-dark" value="0">0 - nacional.</option>
                      <option class="bg-dark" value="1">
                        1 - estrangeira (importação direta).
                      </option>
                      <option class="bg-dark" value="2">
                        2 - estrangeira (adquirida no mercado interno).
                      </option>
                      <option class="bg-dark" value="3">
                        3 - nacional com mais de 40% de conteúdo estrangeiro.
                      </option>
                      <option class="bg-dark" value="4">
                        4 - nacional produzida através de processos produtivos
                        básicos.
                      </option>
                      <option class="bg-dark" value="5">
                        5 - nacional com menos de 40% de conteúdo estrangeiro.
                      </option>
                      <option class="bg-dark" value="6">
                        6 - estrangeira (importação direta) sem produto nacional
                        similar.
                      </option>
                      <option class="bg-dark" value="7">
                        7 - estrangeira (adquirida no mercado interno) sem
                        produto nacional similar.
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
                <div class="col-md-4">
                  <ValidationProvider
                    name="ICMS Mod Base Calculo"
                    rules="required"
                    slot="{ errors }"
                  >
                    <label
                      for="inputICMSModBaseCalc"
                      class="form-label text-dark"
                      >ICMS Modalidade Base Calculo
                      <span class="text-danger">(*)</span></label
                    >
                    <select
                      v-model="formProduct.icms_mod_rate_base"
                      class="form-control text-dark"
                      id="inputICMSModBaseCalc"
                    >
                      <option class="bg-dark" value="0">
                        0 – margem de valor agregado (%).
                      </option>
                      <option class="bg-dark" value="1">
                        1 – pauta (valor).
                      </option>
                      <option class="bg-dark" value="2">
                        2 – preço tabelado máximo (valor).
                      </option>
                      <option class="bg-dark" value="3">
                        3 – valor da operação.
                      </option>
                    </select>
                  </ValidationProvider>
                </div>
                <div class="col-md-2">
                  <ValidationProvider
                    name="ICMS Aliquota"
                    rules="numeric|max_value:100"
                    v-slot="{ errors }"
                  >
                    <label for="inputICMSAliquota" class="form-label text-dark"
                      >ICMS Aliquota %
                      <span class="text-danger">(*)</span></label
                    >
                    <input
                      style="width: 80px"
                      max="100"
                      v-model="formProduct.icms_rate"
                      type="number"
                      class="form-control text-dark"
                      id="inputICMSAliquota"
                    />
                    <span class="fw-normal text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-secondary mr-3"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button class="btn btn-primary ml-3" type="submit">
                    Atualizar Produto
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Create Product -->

    <!-- Modal Create Category -->
    <div
      class="modal fade"
      id="modalUCreateCategory"
      tabindex="10"
      aria-labelledby="modalUCreateCategoryLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUCreateCategoryLabel">
              Criar categoria
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver
              ref="modalUCreateCategory"
              v-slot="{ handleSubmit }"
            >
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="handleSubmit(onSubmitCreateCategory)"
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label for="inputName" class="form-label text-dark"
                          >Nome da Categoria
                          <span class="text-danger">(*)</span></label
                        >
                        <input
                          v-model="formCategory.name"
                          type="text"
                          class="form-control text-dark"
                          id="inputName"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Criar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Create Category -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  alphaNum,
  maxValue,
  numericField,
  positive,
  validationImage,
  validationRequire,
} from "../middleware/validations";
import LoadingSpinner from "./Spinner.vue";
validationRequire();
validationImage();
alphaNum();
numericField();
positive();
maxValue();

export default {
  name: "ModalUpdateProduct",
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
  },
  props: {
    product: {
      product_id: String,
      image: String,
      bar_code: String,
      title: String,
      description: String,
      cod_ncm: String,
      cfop: String,
      commercial_unit: String,
      tax_unit: String,
      icms_origin: String,
      icms_rate: String,
      icms_mod_rate_base: String,
      category_id: Number,
      status: Number,
      shelf: Number,
    },
  },
  data() {
    return {
      loading: false,
      products: [],
      categories: [],
      formCategory: {
        name: "",
      },
      productImage: "",
      selectedCategory: null,
      formProduct: {
        file: null,
        title: "",
        description: "",
        bar_code: "",
        cod_ncm: "",
        commercial_unit: "",
        tax_unit: "",
        cfop: "",
        icms_origin: "",
        icms_rate: 0,
        icms_mod_rate_base: "",
        shelf: false,
      },
    };
  },
  async mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    if (!company_id || !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    await this.getCategories();
    await this.getProduct();
  },
  methods: {
    async getProduct() {
      if (!this.product) {
        this.$toast.error("Produto não encontrado");
        return;
      }
      this.productImage = this.product.image;
      this.formProduct.product_id = this.product.product_id;
      this.formProduct.title = this.product.title;
      this.formProduct.description = this.product.description;
      this.formProduct.bar_code = this.product.bar_code;
      this.formProduct.cod_ncm = this.product.cod_ncm;
      this.formProduct.cfop = this.product.cfop;
      this.formProduct.commercial_unit = this.product.commercial_unit;
      this.formProduct.tax_unit = this.product.tax_unit;
      this.formProduct.icms_origin = this.product.icms_origin;
      this.formProduct.icms_rate = this.product.icms_rate;
      this.formProduct.icms_mod_rate_base = this.product.icms_mod_rate_base;
      this.formProduct.shelf = this.product.shelf ? true : false;
      const getCategory = this.categories.find(
        (item) => item.id == this.product.category_id
      );
      if (getCategory) {
        this.selectedCategory = { id: getCategory.id, name: getCategory.name };
      }
      this.formProduct.discount = this.product.discount;
    },
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },
    async getCategories() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/categories");
        let categories = data.categories;
        this.categories = categories;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitCreateCategory() {
      try {
        this.loading = true;
        if (!this.formCategory.name) return;
        const { data } = await this.$http.post("/categories", {
          name: this.formCategory.name,
        });
        await this.getCategories();
        this.selectedCategory = data.category;
        this.formCategory.name = "";
        this.$refs.modalUCreateCategory.reset();
        this.modalState("modalUCreateCategory", "hide");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e);
      if (valid) {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.addEventListener("load", (e) => {
            const readerTarget = e.target;
            this.productImage = readerTarget.result;
          });
          reader.readAsDataURL(file);
          this.formProduct.image = file;
        }
      }
    },
    async onSubmitUpdateProduct() {
      try {
        this.loading = true;
        let formData = new FormData();
        this.formProduct.shelf = this.formProduct.shelf ? 1 : 0;
        if (!this.formProduct.image) {
          formData.append("image", this.productImage);
        } else {
          formData.append(
            "image",
            this.formProduct.image,
            this.formProduct.image.name
          );
        }
        formData.append("product_id", this.formProduct.product_id);
        formData.append("title", this.formProduct.title);
        formData.append("description", this.formProduct.description);
        formData.append("bar_code", this.formProduct.bar_code);
        formData.append("cod_ncm", this.formProduct.cod_ncm);
        formData.append("cfop", this.formProduct.cfop);
        formData.append("commercial_unit", this.formProduct.commercial_unit);
        formData.append("tax_unit", this.formProduct.tax_unit);
        formData.append("icms_origin", this.formProduct.icms_origin);
        formData.append("icms_rate", this.formProduct.icms_rate);
        formData.append(
          "icms_mod_rate_base",
          this.formProduct.icms_mod_rate_base
        );
        formData.append("shelf", this.formProduct.shelf);
        formData.append("category_id", this.selectedCategory.id);
        const response = await this.$http.put(
          `/product/${this.formProduct.product_id}`,
          formData
        );
        this.$toast.success("Ótimo! Seu produto foi atualizado com sucesso!");
        this.modalState("modalUpdateProduct", "hide");
        this.cleanFields();
        this.loading = false;
        this.$emit("receiveUpdated");
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    cleanFields() {
      this.formProduct.product_id = "";
      this.formProduct.file = null;
      this.formProduct.title = "";
      this.formProduct.description = "";
      this.formProduct.bar_code = "";
      this.formProduct.cod_ncm = "";
      this.formProduct.cfop = "";
      this.formProduct.commercial_unit = "";
      this.formProduct.tax_unit = "";
      this.formProduct.icms_origin = "";
      this.formProduct.icms_rate = 0;
      this.formProduct.icms_mod_rate_base = "";
      this.formProduct.shelf = false;
      this.formProduct.category_id = "";
      this.selectedCategory = null;
    },
  },
  watch: {
    product() {
      this.getProduct();
    },
  },
};
</script>

<style lang="css" >
.modal-xl {
  max-width: 1000px;
  width: 1000px !important;
}

#formImage {
  display: none;
}

.picture {
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #ccc;
  overflow: hidden;
  text-align: center;
  line-height: 260px;
}
.picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
}

.picture-image {
  height: 120px;
}
</style>