<template>
  <div class="row">
    <LoadingSpinner :show="loading" />
    <generate-link></generate-link>
    <card>
      <div
        class="modal fade"
        id="deleteManagerModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteManagerModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteManagerModalLabel">
                Deletar Usuário
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              - O usuário não terá mais acesso ao sistema
            </div>
            <div class="modal-footer justify-content-end">
              <button
                type="button"
                class="btn btn-simple mr-3"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="handleDeleteManager()"
              >
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="card-text bold">Usuários</p>
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Número Telefone</th>
            <th scope="col">Permissão</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="manager in managers" :key="manager.manager_id">
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ manager.name }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ manager.email }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ manager.phone_number }}
              </p>
            </td>
            <td>
              <select
                v-model="manager.role_id"
                class="form-control"
                id="role"
                @change="handleUpdateManager(manager)"
              >
                <option :value="1">Administrador</option>
                <option :value="2">Gerente</option>
                <option :value="3">Inventário</option>
              </select>
            </td>
            <td
              class="cursor"
              data-toggle="modal"
              data-target="#deleteManagerModal"
              @click="selectedManager = manager"
            >
              <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
            </td>
            <td class="cursor">
              <router-link :to="`manager/${manager.manager_id}`"
                >Acessar</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </card>
  </div>
</template>
<script>
import LoadingSpinner from "../components/Spinner";
import GenerateLink from "./Config/GenerateLink.vue";
export default {
  components: {
    GenerateLink,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      managers: [],
      selectedManager: null,
    };
  },
  methods: {
    managerRole(role) {
      const MANAGER_ROLE = {
        1: "Administrador",
        2: "Gerente",
        3: "Invenetário",
      };
      return MANAGER_ROLE[role];
    },
    async updateManagerRole(params) {
      this.loading = true;
      await this.$http.put("manager/role", params);
      this.loading = false;
    },
    async deleteManager(params) {
      this.loading = true;
      await this.$http.delete("manager", {
        params,
      });
      this.loading = false;
    },
    async getManagers() {
      try {
        this.loading = true;
        const response = await this.$http.get("manager/company");
        const { manager } = response.data;
        this.managers = manager;
        localStorage.setItem("@Snackly:managers", JSON.stringify(manager));
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleUpdateManager(manager) {
      try {
        const data = {
          user_id: manager.manager_id,
          role_id: manager.role_id,
        };
        await this.updateManagerRole(data);
        await this.getManagers();
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleDeleteManager() {
      try {
        if (!this.selectedManager) return;
        const data = {
          user_id: this.selectedManager.manager_id,
        };
        await this.deleteManager(data);
        await this.getManagers();
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
  async mounted() {
    try {
      await this.getManagers();
    } catch (err) {
      this.loading = false;
      this.$toast.error(this.$handleErrors.message(err));
    }
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
</style>
