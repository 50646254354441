<template>
  <div class="row">
    <LoadingSpinner :show="loading" />
    <div
      class="modal fade"
      id="removeCompanyModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="removeCompanyModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="removeCompanyModalLabel">
              Remover Empresa
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            - O usuário não terá mais acesso a essa empresa
          </div>
          <div class="modal-footer justify-content-end">
            <button
              type="button"
              class="btn btn-simple mr-3"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="handleDeleteCompany()"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalAddCompany"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddCompanyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddCompanyLabel">Empresas</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="mb-3">Selecione uma empresa</p>
            <select
              v-model="selectedCompany"
              class="form-control text-dark"
              id="role"
            >
              <option v-for="company in companies" :key="company.id">
                {{ company.fancy_name }}
              </option>
            </select>
          </div>
          <div class="modal-footer justify-content-end">
            <button
              type="button"
              class="btn btn-simple mr-3"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-success"
              @click="handleAddCompany"
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
    <card>
      <p class="card-text bold">Empresas</p>
      <base-button
        type="success"
        simple
        data-toggle="modal"
        data-target="#modalAddCompany"
        >Adicionar Empresa</base-button
      >
    </card>
    <card>
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">CNPJ</th>
            <th scope="col">Nome Fantasia</th>
            <th scope="col">Razão Social</th>
            <th scope="col">Remover</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in manager.companies" :key="company.id">
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ company.cnpj }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ company.fancy_name }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ company.corporate_name }}
              </p>
            </td>
            <td
              class="cursor"
              data-toggle="modal"
              data-target="#removeCompanyModal"
              @click="selectedDeleteCompany = company"
            >
              <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingSpinner from "../../components/Spinner";
export default {
  components: {
    LoadingSpinner,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      managers: [],
      manager: [],
      companies: [],
      selectedCompany: "",
      selectedManager: null,
      selectedDeleteCompany: {},
    };
  },
  async mounted() {
    this.companies = this.getManager().companies;
    await this.getManagers(this.id);
  },
  methods: {
    ...mapGetters({
      getManager: "manager/getManager",
    }),
    async getManagers(userId) {
      try {
        this.loading = true;
        const response = await this.$http.get("manager/company");
        const { manager } = response.data;
        this.manager = manager.find((manager) => manager.manager_id == userId);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleDeleteCompany() {
      try {
        this.loading = true;
        await this.$http.delete(`manager/company/user/${this.id}`, {
          params: {
            company_id: this.selectedDeleteCompany.id,
          },
        });
        this.$toast.success("Empresa removida com sucesso");
        await this.getManagers(this.id);
        $("#removeCompanyModal").modal("hide");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleAddCompany() {
      try {
        this.loading = true;
        const getCompany = this.companies.find(
          (company) =>
            company.fancy_name.toUpperCase() ==
            this.selectedCompany.toUpperCase()
        );
        await this.$http.post(`manager/company/user/${this.id}`, {
          company_id: getCompany.company_id,
        });
        this.$toast.success("Empresa adicionada com sucesso");
        await this.getManagers(this.id);
        $("#modalAddCompany").modal("hide");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
</style>
