<template>
  <div
    class="modal fade"
    id="modalAddBatch"
    tabindex="-1"
    aria-labelledby="modalAddBatchLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAddBatchLabel">Lançar em Lote</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <ValidationObserver ref="modalAddBatch" v-slot="{ handleSubmit }">
            <form
              class="row g-3 justify-content-center"
              @submit.prevent="
                handleSubmit(() => onSubmitAddBatchProduct('modalAddBatch'))
              "
            >
              <div class="col-md-12">
                <div class="d-flex" style="justify-content: space-between">
                  <div style="width: 55%">
                    <label for="inputProductSelect" class="form-label text-dark"
                      >Produto</label
                    >
                    <multiselect
                      @input="resetBatchItem"
                      :max-height="150"
                      :maxSelectedLabels="1"
                      id="inputProductSelect"
                      :showLabels="false"
                      v-model="selectedBatchProduct"
                      :options="products"
                      placeholder="Nome do produto"
                      label="title"
                      track-by="title"
                    >
                      <template #option="slotProps">
                        <div class="flex align-items-center text-dark">
                          <img
                            :alt="slotProps.option.title"
                            :src="slotProps.option.image"
                            class="mr-2"
                            style="width: 18px"
                          />
                          <span>{{ slotProps.option.title }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                  <div style="width: 10%">
                    <label
                      for="inputAcquisitionPrice"
                      class="form-label text-dark"
                      >Preço Produto <span class="text-danger">(*)</span></label
                    >
                    <money
                      :disabled="hasAcquisitionPrice"
                      class="form-control text-dark"
                      id="inputAcquisitionPrice"
                      v-model="batchProduct.acquisition_price"
                      v-bind="money"
                    ></money>
                  </div>
                  <div style="width: 8%">
                    <label for="inputAvailability" class="form-label text-dark"
                      >Quant.</label
                    >
                    <input
                      type="number"
                      min="0"
                      v-model="batchProduct.amount"
                      class="form-control text-dark"
                      id="inputAvailability"
                    />
                  </div>
                  <div>
                    <label
                      for="inputDateExpiration"
                      class="form-label text-dark"
                      >Data de Validade</label
                    >
                    <input
                      type="date"
                      v-model="batchProduct.expiration"
                      class="form-control text-dark"
                      id="inputDateExpiration"
                    />
                  </div>
                  <div>
                    <div>
                      <label for="" class="form-label text-dark"></label>
                    </div>
                    <base-button class="mt-0" simple @click="addProduct">
                      Adicionar
                    </base-button>
                  </div>
                </div>
                <div class="lote-list mt-4 h4">
                  <div
                    class="lote-item d-flex"
                    v-for="product in listaLote"
                    :key="`${product.expiration}-${product.id}`"
                  >
                    <p>{{ product.name }}</p>
                    <p
                      class="personalized-badge text-dark mx-2"
                      :class="isValidDate(product.expiration)"
                    >
                      {{ dateFormatted(product.expiration) }}
                    </p>
                    <p>{{ product.quantity }}x</p>
                    <p>{{ toBRL(product.acquisition_price) }}</p>
                    <base-button link @click="() => removeProduct(product)">
                      <unicon
                        name="trash-alt"
                        fill="#fd5d93"
                        width="22px"
                      ></unicon>
                    </base-button>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="nfe"
                      rules="ext:'jpg,png,pdf,jpeg'|required"
                      ref="inputImageRef"
                      v-slot="{ errors }"
                    >
                      <label for="formFile" class="form-label text-dark"
                        >Anexar NF-e <span class="text-danger">*</span></label
                      >
                      <input
                        @change="handleFileChange"
                        type="file"
                        name="formFile"
                        class="form-control text-dark"
                        id="formFile"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row row py-4">
                  <div class="col-12 text-right">
                    <base-button
                      simple
                      type="danger"
                      class="mr-3"
                      data-dismiss="modal"
                    >
                      Fechar
                    </base-button>
                    <button
                      class="btn btn-success"
                      type="submit"
                      :disabled="loading"
                    >
                      Criar Lote
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  positive,
  validationRequire,
  extensionValidation,
} from "@/middleware/validations";
validationRequire();
positive();
extensionValidation();

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    Money,
  },
  props: {
    stockProducts: Array,
    products: Array,
    companyId: Number,
    warehouseId: Number,
  },
  data() {
    return {
      loading: false,
      formProduct: {
        file: null,
      },
      selectedBatchProduct: {},
      hasAcquisitionPrice: false,
      batchProduct: {
        expiration: "",
        amount: 0,
        acquisition_price: 0,
        action: 1,
      },
      listaLote: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },
    addProduct() {
      const hasProduct = this.listaLote.some(
        (product) =>
          product.expiration == this.batchProduct.expiration &&
          product.id == this.selectedBatchProduct.product_id
      );
      if (hasProduct) {
        this.$toast.info("Produto já inserido no lote");
      } else {
        if (
          !this.batchProduct.expiration ||
          !this.batchProduct.acquisition_price ||
          !this.batchProduct.amount ||
          !this.selectedBatchProduct.product_id
        )
          return;

        this.listaLote.push({
          id: this.selectedBatchProduct.product_id,
          image: this.selectedBatchProduct.image,
          name: this.selectedBatchProduct.title,
          expiration: this.batchProduct.expiration,
          quantity: this.batchProduct.amount,
          acquisition_price: this.batchProduct.acquisition_price,
        });
        this.$toast.success("Produto adicionado ao lote");
        this.resetBatchItem();
        this.selectedBatchProduct = {};
      }
    },
    removeProduct(product) {
      this.listaLote = this.listaLote.filter(
        (batchProduct) =>
          product.expiration != batchProduct.expiration ||
          product.id != batchProduct.id
      );
    },
    async handleFileChange(e) {
      const { valid } = await this.$refs.inputImageRef.validate(e);
      if (valid) {
        this.formProduct.file = e.target.files[0];
      }
    },
    async onSubmitAddBatchProduct(modalId) {
      try {
        if (this.listaLote.length == 0) return;
        this.loading = true;
        let formData = new FormData();
        formData.append(
          "image",
          this.formProduct.file,
          this.formProduct.file.name
        );
        const items = this.listaLote.map((product) => ({
          acquisition_price: parseInt(product.acquisition_price * 100),
          quantity: product.quantity,
          expiration: product.expiration,
          product_id: product.id,
        }));
        formData.append("items", JSON.stringify(items));
        formData.append("company_id", this.companyId);
        formData.append("warehouse_id", this.warehouseId);
        await this.$http.post("/lote", formData);
        this.$emit("refresh-page");
        this.$refs.modalAddBatch.reset();
        this.modalState(modalId, "hide");
        this.cleanAddBatchModal();
        this.$toast.success("Lote Criado com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    resetBatchItem() {
      this.batchProduct.expiration = "";
      this.batchProduct.amount = 0;
      this.batchProduct.acquisition_price = 0;
      this.batchProduct.action = 1;
      this.$refs.modalAddBatch.reset();
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    dateFormatted(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    cleanAddBatchModal() {
      this.selectedBatchProduct = {};
      this.listaLote = [];
      this.hasAcquisitionPrice = false;
      this.batchProduct.expiration = "";
      this.batchProduct.amount = 0;
      this.batchProduct.acquisition_price = 0;
      this.batchProduct.action = 1;
    },
  },
};
</script>

<style lang="css" scoped>
.form-control[disabled] {
  background-color: #fff;
  border-color: #e8e8e8;
  color: #838383 !important;
}
.transition-animation {
  animation: refresh 600ms ease both;
}

.personalized-badge {
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

@keyframes refresh {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

.details {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

table {
  border-radius: 12px;
  background: transparent;
}

thead th {
  padding: 40px 0;
}

tbody td {
  border-color: rgba(14, 14, 20, 0.329) !important;
  cursor: pointer !important;
  padding: 0;
  width: 160px;
  max-width: 160px;
  word-wrap: break-word;
  /* white-space: pre-line; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

thead th {
  padding: 0;
  width: 160px;
  max-width: 160px;
  color: #ffffff !important;
}

tbody p span {
  font-size: 12px;
}

div.modal-footer {
  justify-content: flex-end;
}

div.lote-list {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  padding: 1rem;
  height: 250px;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}

.lote-list .lote-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

div.modal-dialog {
  transform: translate(0, 0) !important;
}

.modal-xl {
  max-width: 1220px;
  width: 1220px !important;
}
</style>
