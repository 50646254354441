<template>
  <card class="p-4" title="Produtos">
    <!-- Modal Delete Product -->
    <div
      class="modal fade"
      id="deleteProductModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteProductModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteProductModalLabel">
              Deletar Produto
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            - Produto será deletado do banco de dados
          </div>
          <div class="modal-footer justify-content-end">
            <base-button
              class="btn btn-success mr-3"
              data-dismiss="modal"
              simple
            >
              Cancelar
            </base-button>
            <base-button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteProduct()"
            >
              Deletar
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Delete Product -->

    <ModalCreateProduct @receiveCreated="getProducts" />
    <ModalUpdateProduct
      @receiveUpdated="getProducts"
      :product="updateProduct"
    />
    <LoadingSpinner :show="loading" />

    <div class="row my-5 justify-content-between align-items-center">
      <div class="col-12 col-md-6">
        <label>Procurar produtos</label>
        <base-input v-model="search" placeholder="Ex:. Coca cola"></base-input>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <base-refresh
          class="mr-3"
          :isRefreshing="isRefreshing"
          @click="() => refreshProducts()"
        />
        <div style="width: 60px" class="mr-3">
          <select
            class="form-control"
            v-model="limitPage"
            @change="handleChangeLimit"
          >
            <option
              v-for="number in [5, 10, 20, 50, 100]"
              :key="number"
              :value="number"
            >
              {{ number }}
            </option>
          </select>
        </div>
        <base-button
          type="success"
          simple
          data-toggle="modal"
          data-target="#modalCreateProduct"
          >Criar produto</base-button
        >
      </div>
    </div>

    <table class="table table-responsive-xl text-center">
      <thead>
        <tr>
          <th scope="col">Imagem do Produto</th>
          <th scope="col">Produto</th>
          <th scope="col">Descrição</th>
          <th scope="col">Cod. Barras</th>
          <th scope="col">Editar</th>
          <th scope="col">Deletar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product of filteredProducts" :key="product.product_id">
          <td>
            <ProductImage :size="50" :src="product.image" />
          </td>
          <td>
            <p class="text-white mx-2 font-weight-bold">
              {{ product.title }}
            </p>
          </td>
          <td>
            <p class="text-white mx-2 font-weight-bold">
              {{ product.description }}
            </p>
          </td>
          <td>
            <p class="text-white mx-2 font-weight-bold">
              {{ product.bar_code }}
            </p>
          </td>
          <td>
            <unicon
              style="cursor: pointer"
              data-toggle="modal"
              data-target="#modalUpdateProduct"
              name="edit"
              fill="#1d8cf8"
              width="22px"
              @click="() => (updateProduct = product)"
            ></unicon>
          </td>
          <td
            data-toggle="modal"
            data-target="#deleteProductModal"
            @click="deleteProductSelected = product"
          >
            <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
          </td>
        </tr>
      </tbody>
    </table>
    <paginate
      :page-count="totalPages"
      :page-range="3"
      :click-handler="handleClickPage"
      prev-text="<"
      next-text=">"
      container-class="paginator-container"
      page-class="page-item"
    >
    </paginate>
  </card>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProductImage from "../components/ProductImage.vue";
import LoadingSpinner from "../components/Spinner.vue";
import { positive, validationRequire } from "../middleware/validations";
import NotificationTemplate from "./Notifications/NotificationTemplate";
import ModalCreateProduct from "../components/ModalCreateProduct.vue";
import ModalUpdateProduct from "../components/ModalUpdateProduct.vue";
import BaseRefresh from "../components/BaseRefresh.vue";
import Paginate from "vuejs-paginate";

validationRequire();
positive();

export default {
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    ProductImage,
    ModalCreateProduct,
    ModalUpdateProduct,
    BaseRefresh,
    Paginate,
  },
  data() {
    return {
      loading: false,
      isRefreshing: false,
      totalPages: 0,
      limitPage: 10,
      page: 1,
      search: "",
      products: [],
      company_id: "",
      warehouse_id: "",
      addActions: [
        { label: "ENTRADA NF", value: 1 },
        { label: "ENTRADA DEVOLUCAO VENDA", value: 2 },
        { label: "ENTRADA DE PRODUCAO", value: 3 },
        { label: "ENTRADA BONIFICACAO", value: 4 },
        { label: "ENTRADA DOACAO", value: 5 },
        { label: "ENTRADA OUTRAS", value: 6 },
      ],
      deleteProductSelected: {
        product_id: "",
      },
      addProductSelected: {
        product_id: "",
        amount: 0,
        action: 1,
        expiration: "",
      },
      updateProduct: {},
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.getProducts();
  },
  methods: {
    handleChangeLimit() {
      this.getProducts();
    },
    handleClickPage(pageNumber) {
      this.page = pageNumber;
      this.getProducts();
    },
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    notifyVue(verticalAlign, horizontalAlign, message, type) {
      this.$notify({
        component: NotificationTemplate,
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type,
        timeout: 0,
      });
    },
    handleEdit(id) {
      this.$router.push({
        name: "product",
        params: { id },
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
    async refreshProducts() {
      this.isRefreshing = true;
      await this.getProducts();
    },
    async getProducts() {
      try {
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          page: this.page,
          limit: this.limitPage,
        };
        this.loading = true;
        const { data } = await this.$http.get("/products", {
          params,
        });
        this.products = data.products;
        this.totalPages = data.total_pages;
        this.page = data.page;
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isRefreshing = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async deleteProduct() {
      try {
        this.loading = true;
        await this.$http.delete(
          `/product?product_id=${this.deleteProductSelected.product_id}`
        );
        await this.getProducts();
        this.$toast.success("Produto deletado com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
  computed: {
    isManagerAdmin() {
      return this.$store.getters["manager/isManagerAdmin"];
    },
    filteredProducts() {
      return this.products.filter((product) => {
        return (
          product.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        );
      });
    },
  },
};
</script>

<style lang="css" scoped>
.not-manager {
  filter: brightness(50%);
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

div.modal-footer {
  justify-content: flex-end;
}
</style>