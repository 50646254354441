<template>
  <card title="Compras">
    <LoadingSpinner :show="loading" />
    <!-- Begin Details Modal   -->
    <div
      class="modal fade"
      id="modalOrderDetails"
      aria-labelledby="modalOrderDetailsLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="modalOrderDetailsLabel">
              Detalhes do Pedido
            </p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
              <p class="display-4">
                Número do Pedido #{{ orderSelected.order_id }}
              </p>
              <CustomBadge
                class="ml-4"
                :text="PAYMENTS_STATUS[orderSelected.status.toLowerCase()]"
                :type="paymentStatus(orderSelected.status)"
              />
            </div>
            <table class="table table-responsive-xl text-dark">
              <thead>
                <tr>
                  <th scope="col">
                    <p class="font-weight-bold">IMAGEM</p>
                  </th>
                  <th scope="col"><p class="font-weight-bold">NOME</p></th>
                  <th scope="col">
                    <p class="font-weight-bold">COD DE BARRAS</p>
                  </th>
                  <th scope="col"><p class="font-weight-bold">QUANT</p></th>
                  <th scope="col"><p class="font-weight-bold">TOTAL</p></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in orderSelected.products"
                  :key="item.product_id"
                >
                  <td>
                    <ProductImage
                      class="ml-0"
                      :src="item.product_image"
                      :size="45"
                    />
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      {{ item.product_name }}
                    </p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">{{ item.barcode }}</p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      {{ item.quantity }}x {{ toBRL(item.price) }}
                    </p>
                  </td>
                  <td>
                    <p class="text-dark font-weight-bold">
                      {{ toBRL(item.price * item.quantity) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- End Details Modal   -->

    <!-- <div class="filter-container">
      <div class="container-input initial text-left">
        <label for="start-date">De</label>
        <input v-model="dateFilter.start" type="date" id="start-date" />
      </div>

      <div class="container-input final text-left">
        <label for="end-date">Até</label>
        <input v-model="dateFilter.end" type="date" id="end-date" />
      </div>
    </div> -->

    <!-- <div class="row my-2 justify-content-start">
      <div class="col-md-4 text-left">
        <label>Procurar máquinas</label>
        <base-input v-model="search" addonLeftIcon="tim-icons icon-zoom-split" placeholder="Ex:. Coca cola"></base-input>
      </div>
    </div> -->
    <div class="d-flex justify-content-end align-items-center">
      <base-refresh
        class="mr-3"
        :isRefreshing="isRefreshing"
        @click="refreshGetOrders"
      ></base-refresh>
      <div style="width: 60px">
        <select
          class="form-control"
          v-model="limitPage"
          @change="handleChangeLimit"
        >
          <option
            v-for="number in [5, 10, 20, 100]"
            :key="number"
            :value="number"
          >
            {{ number }}
          </option>
        </select>
      </div>
    </div>
    <table class="table table-responsive-xl">
      <thead>
        <tr>
          <th scope="col">Pedido</th>
          <th scope="col">Data</th>
          <th scope="col">Usuário</th>
          <th scope="col">Máquina</th>
          <th scope="col">Total</th>
          <th scope="col">Status</th>
          <th scope="col">Tipo</th>
          <th scope="col">Detalhes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.order_id">
          <td>
            <p class="text-white font-weight-bold">#{{ order.order_id }}</p>
          </td>
          <td>
            <p class="text-white font-weight-bold">
              {{ dateFormatted(order.date) }}
            </p>
          </td>
          <td>
            <p class="text-white font-weight-bold">{{ order.user }}</p>
          </td>
          <td>
            <p class="text-white font-weight-bold">{{ order.machine }}</p>
          </td>
          <td>
            <p class="text-white font-weight-bold">{{ toBRL(order.total) }}</p>
          </td>
          <td>
            <CustomBadge
              :text="PAYMENTS_STATUS[order.status.toLowerCase()]"
              :type="paymentStatus(order.status)"
            />
          </td>
          <td>
            <p class="text-white font-weight-bold">
              {{ PAYMENTS_TYPE[order.type.toLowerCase()] }}
            </p>
          </td>
          <td>
            <base-button
              link
              data-toggle="modal"
              data-target="#modalOrderDetails"
              @click="orderSelected = order"
            >
              <unicon name="list-ul" fill="#fff" width="22px"></unicon>
            </base-button>
          </td>
        </tr>
      </tbody>
    </table>
    <paginate
      :page-count="totalPages"
      :page-range="3"
      :click-handler="handleClickPage"
      prev-text="<"
      next-text=">"
      container-class="paginator-container"
      page-class="page-item"
    >
    </paginate>
  </card>
</template>
<script>
import moment from "moment-timezone";
import LoadingSpinner from "../components/Spinner.vue";
import CustomBadge from "../components/CustomBadge/CustomBadge.vue";
import ProductImage from "../components/ProductImage.vue";
import { format } from "../mixins/format.js";
import BaseRefresh from "../components/BaseRefresh.vue";
import Paginate from "vuejs-paginate";

export default {
  components: {
    LoadingSpinner,
    CustomBadge,
    ProductImage,
    BaseRefresh,
    Paginate,
  },
  mixins: [format],
  data() {
    return {
      loading: false,
      isRefreshing: false,
      totalPages: 0,
      limitPage: 100,
      page: 1,
      warehouse_id: "",
      company_id: "",
      PAYMENTS_STATUS: {
        paid: "Pago",
        pending: "Pendente",
        canceled: "Cancelado",
      },
      PAYMENTS_TYPE: {
        credit: "Crédito",
        creditcard: "Crédito",
        debit: "Débito",
        debitcard: "Débito",
        vouchercard: "Voucher",
        pix: "Pix",
      },
      dateFilter: {
        start: "",
        end: "",
      },
      orders: [],
      search: "",
      orderSelected: {
        status: "paid",
        type: "credit",
      },
    };
  },
  async mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getOrders();
  },
  methods: {
    handleChangeLimit() {
      this.getOrders();
    },
    handleClickPage(pageNumber) {
      this.page = pageNumber;
      this.getOrders();
    },
    dateFormatted(date) {
      const desiredTimezone = "America/Recife";
      const datetime = moment.utc(date);
      const dateTimeRecife = datetime.clone().tz(desiredTimezone);
      return dateTimeRecife.format("lll");
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    paymentStatus(payment) {
      const data = {
        paid: "success",
        pending: "info",
        canceled: "danger",
      };
      return data[payment.toLowerCase()];
    },
    async refreshGetOrders() {
      this.isRefreshing = true;
      await this.getOrders();
    },
    async getOrders() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          page: this.page,
          limit: this.limitPage,
        };
        const { data } = await this.$http.get("/machine/purchase", { params });
        this.orders = data.orders;
        this.totalPages = data.total_pages;
        this.page = data.page;
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = false;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>

<style lang="css" >
.paginator-container {
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  list-style: none;
  color: #d1d2d6;
}

.page-item > a {
  display: block;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  border-radius: 4px;
  color: #f5f5f5;
}

.card {
  padding: 20px !important;
}
</style>